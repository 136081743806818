import { useState, useEffect, useCallback, useRef } from 'react'
import debounce from 'lodash.debounce'
import _ from 'promise-polyfill'
import 'whatwg-fetch'
import { Block, Tab } from '@posten/hedwig-react'
import Loader from './Loader.jsx'
import Hit from './Hit.jsx'

import './search.scss'

export const Search = ({ settings, q, localization, image, noHitsHtml }) => {
    const [selectedCategory, setSelectedCategory] = useState('all')
    const [visibleCount, setVisibleCount] = useState(settings.startCount || 10)
    const [searchText, setSearchText] = useState(q || '')
    const [counts, setCounts] = useState({})
    const [error, setError] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const firstRenderFinihsed = useRef(false)
    const [hits, setHits] = useState([])
    const [offset, setOffset] = useState(0)
    const [categories, setCategories] = useState([])

    let cache = []

    const search = (term, category) => {
        setIsLoading(true)
        const l = window.location
        const path = l.origin + l.pathname + '?q=' + term + l.hash
        const stateObj = { searchText: term }
        history.replaceState(stateObj, searchText, path)

        handleSearch(
            term,
            0,
            settings.startCount + settings.loadMoreCount,
            category
        )
    }

    const searchDebounced = useCallback(debounce(search, 500), [])

    // Initial search on first render
    useEffect(() => {
        search(searchText, selectedCategory)
        // Initializing hedwig search js module for styling purposes
        if (hedwig) {
            hedwig.HWSearch()
        }
    }, [])

    useEffect(() => {
        if (firstRenderFinihsed.current && searchText.length > 3) {
            searchDebounced(searchText, selectedCategory)
        } else if (!firstRenderFinihsed.current) {
            firstRenderFinihsed.current = true
        }
    }, [searchText])

    const handleShowMore = () => {
        // Show the next hits we have from before
        setVisibleCount(visibleCount + settings.loadMoreCount)
        setOffset(offset + settings.loadMoreCount)
        // Get next batch of hits
        handleSearch(
            searchText,
            offset,
            settings.loadMoreCount,
            selectedCategory
        )
    }

    /**
     * Handle all search queries to backend
     * @param searchTerm: term to search for
     * @param offset: how many hits to skip
     * @param count: how many hits to fetch
     * @param category: what category are we searching
     */
    const handleSearch = (searchTerm, offset, count, category) => {
        const searchUrl = `${settings.searchServiceUrl}?q=${searchTerm}&type=${category}&start=${offset}&count=${count}`
        const cache_ = _.resolve(cache[searchUrl])
        cache_
            .then((cacheResult) => {
                if (cacheResult) {
                    return cacheResult
                } else {
                    return fetch(searchUrl).then((fetchedData) => {
                        // Save json data in cache
                        cache[searchUrl] = fetchedData.json()
                        return _.resolve(cache[searchUrl])
                    })
                }
            })
            .then(
                (result) => {
                    /*
                    Only display search result if the search term is the same
                    This prevents showing previous search results
                    */
                    if (result.request.params.q == searchTerm) {
                        // Display the search results
                        setHits(
                            offset > 0 ? [...hits, ...result.hits] : result.hits
                        )
                        setCounts(result.counts)
                        setOffset(offset + count)

                        // Finding "content" and moving it to 0 index
                        if(result.categories.includes("content")){
                            const indexValue = result.categories.indexOf("content");
                            const content = result.categories.splice(indexValue,1).toString();
                            result.categories.unshift(content);
                        }
                        
                        setCategories(result.categories)
                        // Initialise curtains used in addresses
                        if (hedwig) {
                            hedwig.HWCurtain()
                        }
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error)
                }
            )
            .finally((_) => setIsLoading(false))
    }

    const setCategory = (category) => {
        if (category === selectedCategory) {
            return
        }
        setSelectedCategory(category)
        setVisibleCount(settings.startCount)
        handleSearch(
            searchText,
            0,
            settings.startCount + settings.loadMoreCount,
            category
        )
    }

    const getCategoryLabel = (category) => {
        switch (category) {
            case 'others':
                return localization.others.categoryLabel
            case 'content':
                return localization.categoryContentLabel
            case 'unit':
                return localization.unit.categoryLabel
            case 'article':
                return localization.article.categoryLabel
            case 'postalCode':
                return localization.postalCode.categoryLabel
            case 'address':
                return localization.address.categoryLabel
            case 'all':
                return localization.categoryAllLabel
            case 'rutiner':
                return localization.rutiner.categoryLabel
        }
    }

    const tabItems = () =>
        ['all', ...categories].map((category) => ({
            id: category,
            content: (
                <>
                    {getCategoryLabel(category)}{' '}
                    <span className='hw-text-smaller'>
                        ({counts[category]})
                    </span>
                </>
            )
        }))

    const shouldDisplayShowMoreButton =
        hits.length > 0 && counts[selectedCategory] > visibleCount && !isLoading

    const HeroImage = ({ image }) =>
        image && image.large ? (
            <div
                className='hw-hero__image'
                style={{ backgroundImage: `url(${image.large})` }}
            ></div>
        ) : null

    return (
        <>
            <div className='hw-hero hw-hero--small'>
                <HeroImage image={image} />
                <div className='hw-hero__overlay'>
                    <div className='hw-container' style={{ height: '100%' }}>
                        <div className='hw-hero__content hw-hero__content--bottom'>
                            <div className='hw-grid' style={{ width: '100%' }}>
                                <div className='hw-grid__item hw-two-thirds'>
                                    <Block mb='medium-3'>
                                        <h1 className='hw-h1 hw-color-white'>
                                            {localization.title}
                                        </h1>
                                    </Block>
                                    <Block mb='medium-3'>
                                        <div
                                            className='hw-search'
                                            data-hw-search
                                            data-hw-search-placeholders=''
                                        >
                                            <div className='hw-search__inner'>
                                                <form
                                                    id='react-search-form'
                                                    method='get'
                                                    role='search'
                                                    aria-label="${portal.localize({'_key=arialabel.searchfield'})}"
                                                >
                                                    <input
                                                        data-hw-search-input
                                                        defaultValue={q}
                                                        name='q'
                                                        className='hw-search__input'
                                                        type='text'
                                                        aria-label={
                                                            localization.searchFieldPlaceholder
                                                        }
                                                        placeholder={
                                                            localization.searchFieldPlaceholder
                                                        }
                                                        autoComplete='off'
                                                        onChange={(e) =>
                                                            setSearchText(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </form>
                                                <i className='far fa-search hw-search__icon'></i>
                                                <button
                                                    className='hw-search__button-inside'
                                                    data-hw-search-reset
                                                >
                                                    <i className='fas fa-times-circle'></i>
                                                </button>
                                            </div>
                                        </div>
                                    </Block>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section
                id='react-search-results'
                className='hw-container hw-container--mb'
            >
                <Block mt='large-2' mb='medium-3'>
                    <h2 className='hw-h2'>{localization.searchResultLabel}</h2>
                </Block>
                <Block mt='medium-4' mb='medium-3'>
                    <Tab
                        items={tabItems()}
                        selected='all'
                        onClick={setCategory}
                    />
                    <div className='hw-grid'>
                        <div className='hw-grid__item hw-two-thirds'>
                            {isLoading ? (
                                <Block py='on' px='on' mt='on' mb='on'>
                                    <Loader />
                                </Block>
                            ) : (
                                <>
                                    {hits
                                        .slice(0, visibleCount)
                                        .map((hit, index) => (
                                            <Hit
                                                hit={hit}
                                                key={index}
                                                hitIndex={index}
                                                localization={localization}
                                            />
                                        ))}
                                    {shouldDisplayShowMoreButton && (
                                        <Block mt='on' mb='on'>
                                            <span
                                                className='hw-show-more'
                                                onClick={handleShowMore}
                                            >
                                                <button className='hw-show-more__button'>
                                                    {localization.loadMoreLabel}
                                                </button>
                                            </span>
                                        </Block>
                                    )}
                                </>
                            )}
                            {hits.length === 0 && !isLoading && (
                                <div
                                    className='hw-wysiwyg'
                                    dangerouslySetInnerHTML={{
                                        __html: noHitsHtml
                                    }}
                                ></div>
                            )}
                        </div>
                    </div>
                </Block>
            </section>
        </>
    )
}
