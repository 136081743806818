import React from 'react'
import { PrimaryButton } from '@posten-hedwig/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentsAlt } from '@fortawesome/pro-solid-svg-icons'

import loadScript from './loadScript'

export const ChatButton = ({ chatBotUrl, text }) => {
    const handleClick = () => {
        /**
         * We want to load the scripts for chatPanel and chatBot
         * only after clicking to save initial page loading time.
         * First, load the chatPanel script
         */
        loadScript('https://posten.boost.ai/chatPanel/chatPanel.js', 'chatPanelScript', () => {
            // When chatPanel has loaded, load the chatBot script
            loadScript(chatBotUrl, 'chatBotScript', () => {
                // When that has loaded, show the chatPanel
                window.boost.chatPanel.show()
            })
        })
    }
    
    return (
        <div className='hw-block hw-block--mb'>
            <PrimaryButton onClick={() => handleClick()}>
                <FontAwesomeIcon icon={faCommentsAlt} />
                &nbsp;{text}
            </PrimaryButton>
        </div>
    )
}

