const loadScript = (scriptUrl, id, callback) => {
    const existingScript = document.getElementById(id)
    if (!existingScript) {
        const script = document.createElement('script')
        script.src = scriptUrl
        script.id = id
        document.body.appendChild(script)
        script.onload = () => {
            if (callback) callback()
        }
    } else if (callback) callback()
}

export default loadScript
