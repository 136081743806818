import { useState, useEffect } from 'react'
import { Trace } from '@posten/hedwig-react'

import './track.scss'

const DEFAULT_TRACKING_URL = 'https://tracking.bring.com/tracking/'
const START_HTTPS_RX = /^[\s\/]*(https?:\/\/)?/i
const END_SLASH_RX = /[\s\/]*$/

// Use default URL if not set, enforce starting with 'https://' and ending with one slash
const normalizeUrl = (url) => {
    if (!(url || "").trim()) {
        url = DEFAULT_TRACKING_URL
    }
    return url.replace(START_HTTPS_RX, 'https://').replace(END_SLASH_RX, '/')
}

export const Track = ({
    label,
    formLabelColor,
    url,
    buttonLabel,
    placeholder,
    isEnglish,
    ariaLabel
}) => {

    url = normalizeUrl(url);

    const [traceUrl, setTraceUrl] = useState(url)
    const updateTraceUrl = (value) => {
        setTraceUrl(url + value)
    }

    return (
        <>
            <form
                action={traceUrl}
                method='get'
                id='traceForm'
                class={formLabelColor}

                onSubmit={(e) => {
                    e.preventDefault();

                    const inputField = e.target.querySelector('input[type="text"]');

                    const targetUrl = new URL(url)
                    targetUrl.pathname += encodeURI((inputField.value || "").trim())
                    if (isEnglish) {
                        targetUrl.searchParams.set('lang', 'en')
                    }

                    window.location.href = targetUrl.toString();
                }}
            >
                {isEnglish && <input type='hidden' name='lang' value='en' />}

                <label className='trace-label hw-h4'>

                    {label}

                    <Trace
                        buttonLabel={buttonLabel}
                        onChange={(val) => updateTraceUrl(val.target.value)}
                        placeholder={placeholder}
                        ariaLabel={ariaLabel}
                    ></Trace>
                </label>
            </form>
        </>
    )
}
